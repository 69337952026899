@import '../helpers/variables.scss';

    h1 {
        margin-bottom: 30px;
        color: $colour-primary;

        span {
            color: $colour-accent;
            margin-left: 3px;
        }
    }

    h2 {
        font-size: 20px;
        margin-top: 25px;
        color: $colour-primary;
    }

    p {
        color: $colour-primary;
    }
