.modal {
	position: fixed;
    right: 0;
    background: #141719;
    height: 100%;
    width: 50%;
    top: 0;
    z-index: 99999;
    overflow: auto;
    padding: 30px 110px;
	box-shadow: -5px 0 10px rgba(0,0,0,0.2);
	transform: translate(100%,0);
	transition: transform 0.4s ease-in-out;
	
	@media (max-width: $breakpoint-md) {
		width:100%;
		padding:15px;
	}
    
	&-body {
		margin-top:40px;
	}
    &-open-overlay {
        overflow: hidden;
	    &:after {
			content: '';
			background: #000;
			position: fixed;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			z-index: 99;
			opacity: 0.5;
	    }
	}

	&-shift {
        transform: translate(-700px, 0);
	}

	&-open {
		transform: translate(0,0);
	}
	&-close {
        position: absolute;
        top: 10px;
        right: 15px;
        font-size: 40px;
        cursor: pointer;
        color: #fff;
	}
}