.title-wrapper {
    &__subtitle {
        display: block;
        color: #828282;
        font-size: 16px;
        font-weight: 700;
        letter-spacing: 0.05em;
        line-height: 150%;
        text-transform: uppercase;
        position: relative;
        padding-left: 86px;
        margin-bottom: 19px;
        margin-top: -4px;

        &:before {
            content: '';
            position: absolute;
            top: 50%;
            left: 0;
            width: 60px;
            height: 1px;
            background-color: #828282;
            transform: translateY(-50%);
        }
    }

    &__title {
        font-weight: 700;
        font-size: 70px;
        line-height: 90px;
        text-transform: capitalize;
        color: #FFFFFF;
        margin-bottom: 31px;
        display: inline-block;

        @media (max-width: $breakpoint-md) {
            font-size: 50px;
            line-height: 60px;
            margin-top: 30px;
        }

        @media (max-width: $breakpoint-sm) {
            font-size: 40px;
            line-height: 50px;
            margin-top:0;
        }

        span {
            color: $colour-accent;
        }
    }

    &__description {
        max-width: 600px;
        color:#828282;
        line-height: 28px;

        @media (max-width: $breakpoint-md) {
            color: #fff;
        }
    }
}