//$colour-accent : #ffb400;
$colour-accent : #dd4242;
$colour-primary : #fff;

$font-primary : 'Poppins', sans-serif;


/*
*
* Grid Variables
*
*/


 $grid-gutter: 30px !default;
 $grid-max-width: 1200px + ($grid-gutter * 2) !default;
 
 $grid-columns: 12 !default;
 
 $breakpoint-lg: 1280px !default;
 $breakpoint-md: 1024px !default;
 $breakpoint-sm: 768px !default;
 $breakpoint-xs: 480px !default;
 
 $breakpoints: (
     'lg' '(min-width: ' + ($breakpoint-md + 1) + ')',
     'md' '(min-width: ' + ($breakpoint-sm + 1) + ') and (max-width: ' + $breakpoint-md + ')',
     'sm' '(min-width: ' + ($breakpoint-xs + 1) + ') and (max-width:'  + $breakpoint-sm + ')',
     'xs' '(max-width: ' + $breakpoint-xs + ')'
 ) !default;