@import 'helpers/keyframes.scss';
@import 'helpers/variables.scss';

body {
    position: relative;
    height: 100%;

    .preloader {
        position: fixed;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        z-index: 111111111;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        transition: .3s all ease;
        pointer-events: none;
        backface-visibility: hidden;

        &:before, &:after {
            background: #111;
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            transition: .2s linear;
            transform: translateY(0);
            pointer-events: none;
        }
        &:before {
            top: 0;
            bottom: 50%;
        }
        &:after {
            top: 50%;
            bottom: 0;
        }

        &.loaded {
            &:before,
            &:after {
                transition: .4s linear;
            }

            &:before {
                transform: translateY(-101%);
            }
            &:after {
                transform: translateY(101%);
            }
            
            .preloader-container {
                opacity: 0;
                visibility: hidden;
                transition: 0s;
            }
        }

        .preloader-container {
            position: absolute;
            top: 50%;
            transform: translate3d(0, -50%, 0);
            left: 0;
            right: 0;
            z-index: 10;
            text-align: center;

            h1 {
                margin: 0;
                padding: 20px 0;
                color: $colour-primary;
                font-weight: 700;
                font-size: 42px;
            }
        }

        .progress-line-container {
            width: 100vw;
            height: 5px;
            overflow: hidden;
            margin: auto;
            background: #fff;

            .progress-line {
                background: $colour-accent;
                margin-top: 0;
                margin-left: -100vw;
                animation-name: progess_animation;
                animation-duration: 2.5s;
                animation-iteration-count: infinite;
                animation-timing-function: linear;
                width: 100vw;
                height: 5px;
                transition: .5s;
            }
        }
    }
}

  .preloader-text-center {
    transition: 0s .2s;
  }
  
 
  
  .animated {
    animation-duration: 1s;
    animation-fill-mode: both;
    opacity: 1;
  }
  
  .animated.infinite {
    animation-iteration-count: infinite;
  }
  
  .animated.hinge {
    animation-duration: 2s;
  }
  