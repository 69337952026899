.slider {
    &-clients {
        width: 100%;
        position: relative;

        .slick-slide > div {
            margin: 0 10px;
        }
    }
}

.slider-portfolio {
    height: 100vh;
    overflow: hidden;

    .title-wrapper1 {
        transform: translateX(-300px);
    }
    .slick-dots {
        font-family: "Oswald", sans-serif;
        font-weight: 500;
        position: absolute;
        width: auto;
        right: 50px;
        bottom: 50px;
        color: #fff;

        @media (max-width: $breakpoint-md) {
            right: 20px;
            bottom: 80px;
        }

        li {
            span {
                transition: 0.2s all linear;
            }
            &.slick-active {
                span {
                    position: relative;
                    font-size: 26px;
                    color: $colour-accent;
                    letter-spacing: 0.5px;
                    vertical-align: baseline;
                }
            }
        }
    }

    &.slide-loading1 {
        .title-wrapper {
            transition: 0.2s all linear;
            transform: translateX(0);
        }
    }
}