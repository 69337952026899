.nav-wrapper {
    position: absolute;
    z-index: 2;
    top: 30px;
    left: 30px;
    height: initial;
    line-height: initial;
    width: initial;
    background-color: initial;
    box-shadow: none;
    
    .nav-trigger {
        position: absolute;
        z-index: 3;
        top: 0;
        left: 0;
        height: 60px;
        width: 60px;
        border-radius: 50%;
        overflow: hidden;
        white-space: nowrap;
        color: transparent;
        pointer-events: auto;

        span {
            background-color: #ffffff;
            position: absolute;
            width: 16px;
            height: 2px;
            left: 50%;
            top: 50%;
            bottom: auto;
            right: auto;
            transform: translateX(-50%) translateY(-50%);
            transition: background-color 0.2s;

            &::after, &:before {
                content: '';
                top: 0;
                left: 0;
                backface-visibility: hidden;
                transition: transform 0.2s;
                position: absolute;
                width: 16px;
                height: 2px;
                background-color: #ffffff;
            }

            &:before {
                transform: translateY(-6px);
            }

            &:after {
                transform: translateY(6px);
            }
        }
    }

    ul {
        list-style:none;
        position: relative;
        z-index: 2;
        padding: 53px 0 22px;
        visibility: hidden;
        transition: visibility 0.3s;
        text-align: left;

        li {
            float: none;
            transition: initial;

            a {
                position: relative;
                display: block;
                height: 50px;
                line-height: 50px;
                padding: 0 1em 0 21px;
                color: rgba(255,255,255,.9);
                text-decoration: none;
                text-transform: uppercase;
                font-size: 16px;
                transition: color 0.2s;

                &:hover {
                    svg {
                        path {
                            fill: $colour-accent;
                        }
                    }
                }
            }

            span {
                display: inline-block;
                padding-left: 40px;
                opacity: 0;
                transform: translateX(-25px);
            }

            svg {
                opacity: 0;
            }
        }

    }

    .nav-bg {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 60px;
        height: 60px;
        border-radius: 30px;
        background: #202020;
        box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
        transition: height 0.2s, box-shadow 0.2s;
    }

    &.nav-is-visible {
        span {
            background-color: transparent;

            &:before {
                transform: rotate(-45deg);
            }

            &:after {
                transform: rotate(45deg);
            }
        }

        ul {
            visibility: visible;
            li {
                a {
                    &:hover {
                        span {
                            transform: translateX(5px);
                        }
                    }
                }
                span {
                    opacity: 1;
                    transform: translateX(0);
                    animation: slideIn 0.15s backwards;
                    transition: transform 0.2s;
                }

                svg {
                    opacity: 1;
                }
            }
        }

        .nav-bg {
            height: 100%;
            background: #202020;
            box-shadow: 0 6px 30px rgba(0, 0, 0, 0.2);
        }
    }
}