.page-hero {
  display: flex;
  height: 100vh;

  &--homepage {
    .page-hero {
      &__content {
        @media (max-width: 1500px) and (min-width: 1400px) {
          padding: 20px 65px;
        }

        @media (max-width: 1399px) and (min-width: 1024px) {
          padding: 20px 30px;
        }

        @media (max-width: 1249px) and (min-width: 1024px) {
          .title-wrapper__title {
            font-size: 50px;
            line-height: 60px;
          }
        }

        p {
          color: #828282;
        }
      }
    }
  }

  &__image {
    display: flex;
    width: 50%;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(../../../assets/images/home2.jpg);
    position: relative;
    z-index: 0;

    @media (max-width: $breakpoint-md) {
      width: 100%;

      &:before {
        content: '';
        left: 0;
        top: 0;
        background: rgba(0, 0, 0, 0.6);
        width: 100%;
        height: 100%;
        position: absolute;
      }
    }
  }

  &__content {
    display: flex;
    width: 50%;
    justify-content: center;
    flex-flow: column;
    align-items: flex-start;
    padding: 20px 100px;

    @media (max-width: $breakpoint-md) {
      position: absolute;
      width: 100%;
      height: 100%;
      background: transparent;
      padding: 45px 20px;
    }
  }

  &.transition {
    .page-hero__content {
      &:before {
        transform: translateX(0) translateY(0);
        //transition-delay: 1s;
      }
    }
  }
}
