.image-scroller {
    position: relative;
    width: 100%;
    margin: auto;
    border-radius: 5px;
    cursor: wait;
  
    img {
      width: 100%;
      object-fit: cover;
      object-position: top;
      height: 483px;
      transition: 8s all ease;
  
        &:hover {
            object-position: bottom;
        }
    }
}

.scroller-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0,0,0,0.6);

    .mouse-icon {
        border: 2px solid #fff;
        border-radius: 16px;
        display: block;
        height: 50px;
        position: relative;
        width: 30px;
        z-index: 7;

        .scroll {
            animation-delay: 0s;
            animation-duration: 1s;
            animation-iteration-count: infinite;
            animation-name: scrolling;
            animation-play-state: running;
            animation-timing-function: linear;
            background: #fff none repeat scroll 0 0;
            border-radius: 10px;
            height: 10px;
            margin-left: auto;
            margin-right: auto;
            position: relative;
            top: 4px;
            width: 4px;
        }
    }
}