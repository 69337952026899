input, textarea {
    line-height: 40px;
    border-radius: 7px;
    padding-left: 20px;
    width: 100%;
    background-color: #313131;
    color: #fff;
}

.contact-form {
    width: 100%;
    max-width: 600px;
    margin-top: 20px;

    input, textarea {
        margin-bottom: 15px;
    }
}