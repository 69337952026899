body {
  font-family: $font-primary;
  max-width: 2400px;
  display: block;
  margin: 0 auto;
  background: #141719;
}

.page-wrapper {
  position: relative;
}

.colour {
  &-accent {
    color: $colour-accent;
  }

  &-primary {
    color: $colour-primary;
  }
}

.page-portfolio {
  transition: transform 0.4s ease-in-out;
  padding-top: 40px;
}

.list-inline {
  list-style: none;

  & > li {
    display: inline-block;
    margin: 0 4px;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}
@for $i from 1 through 5 {
  .mt-#{$i} {
    margin-top: #{$i}rem;
  }

  .mb-#{$i} {
    margin-bottom: #{$i}rem;
  }
}

.animation {
  &__text-covered {
    position: relative;
    overflow: hidden;
    &:after {
      content: '';
      width: 101%;
      height: 100%;
      background: #f5f5f5;
      position: absolute;
      top: 0;
      left: 100%;
      transition: 1s cubic-bezier(0.86, 0, 0.07, 1);
      z-index: 3;
      transform: translateX(-100%);
      transition-delay: 0.7s;
    }

    .state-ready & {
      &:after {
        transform: translateX(0);
      }
    }
  }

  &__colour-accent {
    &:after {
      background: $colour-accent;
    }
  }

  &__image-covered {
    position: relative;
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      background: #1c1f21;
      z-index: 1111;
      width: 100%;
      height: 100%;
      transform-origin: right;
      transform: scaleX(1);
      transition: 1s cubic-bezier(0.77, 0, 0.175, 1);
      transition-delay: 0.7s;
    }
    .state-ready & {
      &:after {
        transform: scaleX(0);
      }
    }
  }
}
