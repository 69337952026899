.portfolio {
  &-item {
    cursor: pointer;
    margin-bottom: 30px;
    position: relative;
    box-shadow: 0 10px 10px rgba(0,0,0,0.25);
    transition: all 0.4s ease-in-out;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      z-index: 1;
      height: 100%;
      background: linear-gradient(180deg, rgba(25, 28, 38, 0) 0%, #141719 100%);
    }

    img {
      @extend .img-fluid;
      border-radius: 5px;
    }
  }

  &-close {
    float: right;
  }

  &-detail {
    h2 {
      font-weight: 700;
      font-size: 30px;
      color:$colour-accent;
      text-transform: uppercase;
      letter-spacing: 1px;
      margin-top:0;
    }

    p {
      color: #fff;
      font-weight: 300;
    }
  }
  
  &-technology {
    margin-top:30px;
    h3 {
      color: $colour-accent;
      text-transform: uppercase;
      margin-bottom:15px;
    }

    ul {
      margin-bottom:30px;

      li {
        background: #000;
        color:#fff;
        padding: 5px 15px;
        border-radius: 30px;
        font-size: 14px;
        margin-bottom:15px;
      }
    }
  }
}