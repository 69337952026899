@import 'helpers/variables.scss';

.btn {
    border-radius: 26px;
    font-weight: 500;
    height: 46px;
    line-height: 42px;
    font-size: 15px;
    text-transform: uppercase;
    text-decoration: none;
    padding: 0 33px;
    outline: none;
    overflow: hidden;
    display: inline-block;
    position: relative;
    letter-spacing: .5px;
    border: 2px solid transparent;
    transition: 0.2s all linear;
    cursor: pointer;

    & + .btn {
        margin-left:15px;
    }

    &:hover {
        background-color:#666;
        color:#fff;
    }

    &-accent {
        background-color: $colour-accent;
        color: #fff;
    }

    &-primary,
    &-white {
        background-color: #fff;
        color: $colour-accent;
    }

    &-ghost {
        background-color: transparent;
        border:2px solid $colour-accent;
        color: $colour-accent;

        &:hover {
            background-color: $colour-accent;
        }
    }
}