@keyframes progess_animation {
    0% {
      margin-left: -100vw;
    }
  
    100% {
      margin-left: 100vw;
    }
}
@keyframes scrolling {
  0% {
    opacity: 0;
    top: 5px;
  }
  30% {
    opacity: 1;
    top: 10px; 
  }
  100% {
    opacity: 0;
    top: 25px;
  }
}