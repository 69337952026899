@mixin grid-loop($name){
  @for $i from 0 through $grid-columns {
      @if $i != 0 {
          .grid--#{$name}-#{$i} {
              flex-basis: percentage($i/$grid-columns);
              max-width: percentage($i/$grid-columns);
          }
      }
      .grid--#{$name}-offset-#{$i} {
          margin-left: percentage($i/$grid-columns);
      }
  }
  .grid--#{$name}-auto {
      flex-grow: 1;
      flex-basis: 0;
      max-width: 100%;   
  }
}