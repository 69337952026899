.nav-container {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  overflow: hidden;
  margin: 0;
  padding: 0;
  position: fixed;
  top: 0;
  left: 100%;
  transition: 1s cubic-bezier(0.86, 0, 0.07, 1);
  z-index: 100;
  transition-delay: 0.7s;
  transform: translateX(0);

  &.active {
    transform: translateX(-100%);
    transition-delay: 0s;

    .nav-menu:before {
      transform: translateX(0) translateY(0);
      transition: 1s cubic-bezier(0.86, 0, 0.07, 1);
      transition-delay: 1s;
    }

    .menu-close:before {
      transform: translateY(-50%) rotate(225deg);
      transition: 1s cubic-bezier(0.77, 0, 0.175, 1);
      transition-delay: 1s;
    }

    .menu-close:after {
      transform: translateY(-50%) rotate(135deg);
      transition: 1s cubic-bezier(0.77, 0, 0.175, 1);
      transition-delay: 1s;
    }

    .nav-logo img {
      transform: translateY(0);
      transition: 1s cubic-bezier(0.77, 0, 0.175, 1);
      transition-delay: 1s;
    }
  }

  .nav-logo {
    position: absolute;
    top: 40px;
    width: 150px;
    overflow: hidden;
    left: calc(50% + 40px);
    z-index: 3;

    @media (max-width: 767px) {
      left: 50px;
    }

    img {
      width: 100%;
      height: auto;
      display: block;
    }
  }

  .nav-btn {
    position: relative;
    text-transform: uppercase;
    margin: 0;
    padding: 0;
    font-size: 55px;
    line-height: 1;
    font-weight: 500;
    font-family: 'Oswald', sans-serif;
    color: white;
    white-space: nowrap;

    &:before {
      content: attr(data-text);
      position: absolute;
      top: 50%;
      color: #ef0d33;
      transform: translateY(-50%);
      left: 0;
      transition: 0.5s cubic-bezier(0.755, 0.05, 0.855, 0.06);
      width: 0%;
      white-space: nowrap;
      overflow: hidden;
      background: #111517;
    }

    &.active:before {
      width: 100%;
    }
  }
}

.dark-bg-1 {
  background: #111517;
}

.menu-open {
  width: 40px;
  height: 22px;
  right: 40px;
  top: 40px;
  transform-origin: right;
  transform: scaleX(0);
  transition: 1s cubic-bezier(0.77, 0, 0.175, 1);
  transition-delay: 0.7s;
  position: fixed;
  z-index: 1;

  &:before {
    content: '';
    position: absolute;
    width: 40px;
    height: 2px;
    left: 0;
    top: 50%;
    transform-origin: right;
    transform: translateY(-50%) scaleX(1);
    transition: 1s cubic-bezier(0.77, 0, 0.175, 1);
    transition-delay: 1.1s;
    background: white;
  }

  .state-ready & {
    transform: scaleX(1);
    transition: 1s cubic-bezier(0.77, 0, 0.175, 1);
    transition-delay: 0.7s;
  }

  &.pointer-large {
    cursor: pointer;
  }

  .hamburger {
    &:before,
    &:after {
      content: '';
      position: absolute;
      width: 40px;
      height: 2px;
      transition: 1s cubic-bezier(0.77, 0, 0.175, 1);
      left: 0;
      top: 0;
      transition-delay: 1.3s;
      background: #fff;
    }
    &:after {
      top: auto;
      bottom: 0;
    }
  }
}

.menu-close {
  position: absolute;
  top: 40px;
  right: 40px;
  width: 40px;
  height: 22px;
  z-index: 3;
  transition: 0.5s;

  &.pointer-large {
    cursor: pointer;
  }

  &:before,
  &:after {
    content: '';
    width: 40px;
    height: 2px;
    background: white;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%) rotate(0deg);
    transition: 0.7s cubic-bezier(0.77, 0, 0.175, 1);
  }
}

.nav-logo img {
  transform: translateY(101%);
  transition: 0.7s cubic-bezier(0.77, 0, 0.175, 1);
}

.nav-menu {
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column;
  flex-flow: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 50%;
  height: 100%;
  margin-left: calc(50% - 2px);
  border-left: 2px solid #111517;
  position: relative;

  @media (max-width: 767px) {
    width: 100%;
    margin-left: 0;
  }

  &:before {
    content: '';
    width: 103%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: #111517;
    transition: 0.7s cubic-bezier(0.86, 0, 0.07, 1);
    transition-delay: 0s;
    z-index: -1;
    transform: translateX(-100%) translateY(0);
  }

  .nav-box {
    margin: 8px 0;
    padding: 0;
    overflow: hidden;

    a {
      transform: translateY(104%);
      transition: 0.7s cubic-bezier(0.77, 0, 0.175, 1);
      margin: 0;
      padding: 0;
      display: inline-block;
      text-decoration: none;

      &:hover .nav-btn:before {
        width: 100%;
      }
    }
  }
}

@for $i from 1 through 8 {
  .nav-container li:nth-last-child(#{$i}) a {
    transition-delay: #{$i * 0.1}s;
  }
  .nav-container.active li:nth-child(#{$i}) a {
    transition-delay: #{1 + ($i * 0.1)}s;
  }
}

.nav-container.active a {
  transition: 1.5s cubic-bezier(0.23, 1, 0.32, 1);
  transform: translateY(0);
}

.nav-bg {
  background-image: url(../../../assets/images/nav.jpeg);
  position: absolute;
  top: 0;
  right: 100%;
  width: calc(100% - 1px);
  height: 100%;
  pointer-events: none;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -2;
  transform: scale(1.1);
  opacity: 0;
  transition: 0.9s cubic-bezier(0.77, 0, 0.175, 1);
}

.nav-bg-change.active .nav-bg {
  transform: scale(1);
  opacity: 1;
}
